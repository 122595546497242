<template>
  <div>
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">专题题库</span>
    </div>
    <div class="_SelectHeader">
      <div>
        <div class="_Select">
          <span>选择专题：</span>
          <el-select
            v-model="searchTheme"
            @change="getcheckTheme"
            clearable
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in themeList"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" @click="getTableData"
          >搜索</el-button
        >
        <el-button :type="activeBtn" @click="handleChecked">设为主题</el-button>
      </div>
      <div>
        <el-button type="primary" @click="addQuestion">添加题库</el-button>
        <el-button class="_ImgBtn" @click="uploadFile" v-show="!isNotPurview"
          ><img src="../../assets/images/img1/import1.png" />导入</el-button
        >
        <input
          type="file"
          @change="importFile(this)"
          id="imFile"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <el-button class="_ImgBtn" @click="exportExcel"
          ><img src="../../assets/images/img1/import2.png" />导出</el-button
        >
        <el-button
          class="_ImgBtn"
          icon="el-icon-delete"
          @click="deleteList"
        ></el-button>
      </div>
    </div>
    <div class="_Table">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        border
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @selection-change="selectAll"
        id="table"
      >
        <el-table-column type="selection"  v-if="isShow" width="60"> </el-table-column>
        <el-table-column type="index" :index="(e) => (currentPage - 1) * pageSize + e + 1" label="序号" width="80" > </el-table-column>
        <el-table-column prop="interpretation" label="应知应会">
        </el-table-column>
        <el-table-column prop="theme" label="专题" width="120">
        </el-table-column>
        <el-table-column  :label="isShow?'操作':''" width="200" >
          <template slot-scope="scope" v-if="isShow">
            <i
              class="el-icon-edit i-popover"
              style="color: #8ac3f5"
              @click="modifyItem(scope.row)"
            >
              修改</i
            >
            <i
              class="el-icon-delete i-popover"
              style="color: red; padding-left: 10px"
              @click="deleteItem(scope.row)"
            >
              删除</i
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="_Pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 30, 50, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listCount"
      >
      </el-pagination>
    </div>
    <Dialog
      :title="isAdd ? '添加题库' : '修改题库'"
      :showTowText="true"
      :showDialog="showDialog"
      width="60%"
      top="10vh"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
      class="add-dialog"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入应知应会"
        v-model="form.Interpretation"
      >
      </el-input>
      <el-input
        type="textarea"
        autosize
        placeholder="请输入判断题（答案是）"
        v-model="form.Question"
      >
      </el-input>
      <el-input
        type="textarea"
        autosize
        placeholder="请输入判断题（答案否）"
        v-model="form.DisturbQuestion"
      >
      </el-input>
      <el-input
        type="textarea"
        autosize
        placeholder="请输入选择题"
        v-model="form.ChoiceQue"
      >
      </el-input>
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入选择题答案"
        v-model="form.ChoiceQueOption"
      >
      </el-input>
      <div class="add-select">
        <div class="add-input">
          <span> 选择题正确答案：</span>
          <el-input placeholder="请输入" v-model="form.ChoiceQueAnswer">
          </el-input>
        </div>
        <div class="_Select">
          <span>选择专题：</span>
          <el-select v-model="form.Theme" clearable placeholder="请选择">
            <el-option
              v-for="item in themeList"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { exportExcel } from '../../modules/ExportExcel'
import { deleteMethod, get, post, put } from '../../api/http'
import XLSX from 'xlsx'
import Dialog from '../../components/dialog/Dialog.vue'
export default {
  components: { Dialog },
  // 定义属性
  data() {
    return {
      isNotPurview: false,
      elementHeight: 0,
      activeBtn: '',
      themeList: [],
      searchTheme: [],
      tableRowIndex: null,
      tableData: [],
      // 分页
      currentPage: 1,
      pageSize: 20,
      listCount: 0,
      // 增删改
      dataItem: {},
      showDialog: false,
      isAdd: false,
      tableData2: [],
      form: {
        Interpretation: '',
        Question: '',
        DisturbQuestion: '',
        ChoiceQue: '',
        ChoiceQueOption: '',
        ChoiceQueAnswer: '',
        Theme: '',
      },
      checkedDelList: [],
      themeItemId: '',
      themeSelectList: [],
      isShow:true,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getThemeList()
    this.getTableData()
    // 普通角色 不能导入题库，其它权限正常
    var roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName.split(',') 
    if (
      roleName.indexOf('股份公司管理员') == -1 &&
      roleName.indexOf('公司管理员') == -1 &&
      roleName.indexOf('部门管理员') == -1
    ) {
      this.isNotPurview = true
    }
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 112)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
    getcheckTheme() {
      get('/api/SettingTheme/GetIsSelect?Theme=' + this.searchTheme).then(
        (res) => {
          if (res.code == 200) {
            this.activeBtn = res.data ? 'primary' : ''
          }
        }
      )
    },
    rowClick(row) {
      this.tableRowIndex = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    // 设置主题
    handleChecked() {
      var data = this.searchTheme.join(',')
      this.searchTheme = data.split(',')
      post('/api/SettingTheme?Theme=' + data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: 'success',
            message: '设置成功',
          })
          this.activeBtn = 'primary'
        }
      })
    },
    getThemeList() {
      get('/api/ThemeQuestionPool/GetTheme').then((resp) => {
        if (resp.code == 200) {
          this.themeList = resp.data
        }
      })
    },
    getTableData() {
      var url1 = '/api/ThemeQuestionPool/GetListPage?'
      var url2 =
        '/api/ThemeQuestionPool/GetByThemePage?Theme=' + this.searchTheme + '&'
      get(
        (this.searchTheme ? url2 : url1) +
          'limit=' +
          this.pageSize +
          '&page=' +
          this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          if(resp.data.length == 0 && this.currentPage > 1){
            this.currentPage --
            this.getTableData()
          }else{
            this.tableData = resp.data
            this.listCount = resp.count
          }
        }
      })
    },
    // 导入/导出
    exportExcel() {
      this.isShow = false;
      var url1 = '/api/ThemeQuestionPool/GetListPage?'
      var url2 =
        '/api/ThemeQuestionPool/GetByThemePage?Theme=' + this.searchTheme + '&'
      get(
        (this.searchTheme ? url2 : url1) +
          'limit=' +
          this.listCount +
          '&page=' +
          this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data
        }
      }).then(() =>{
        exportExcel("#table", "专题题库.xlsx");
        this.isShow = true;
        this.pageSize = 20;
      })
    },
    uploadFile: function () {
      this.imFile = document.getElementById('imFile')
      this.imFile.click()
    },
    importFile: function () {
      var importDataList = []
      let obj = this.imFile
      if (!obj.files) {
        return
      }
      var f = obj.files[0]
      var reader = new FileReader()
      let $this = this
      reader.onload = function (e) {
        var data = e.target.result
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: 'base64',
          })
        } else {
          $this.wb = XLSX.read(data, {
            type: 'binary',
          })
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        )
        //应知应会、专题不能为空
        var stopExecution = false
        excelData.map((item, index) => {
          if (stopExecution) return
          if (
            !Object.prototype.hasOwnProperty.call(item, '应知应会') &&
            index == 0
          ) {
            $this.$message.error('导入文件不符合')
            stopExecution = true
            return
          }

          // 如果没有应知应会和判断题，则为合并后的第二行。
          var name1 = '判断题（答案是）'
          var name2 = '判断题（答案否）'
          if (
            !Object.prototype.hasOwnProperty.call(item, '判断题（答案是）') &&
            !Object.prototype.hasOwnProperty.call(item, '应知应会')
          ) {
            excelData[index][name1] = excelData[index - 1][name1]
          }
          if (
            !Object.prototype.hasOwnProperty.call(item, '判断题（答案否）') &&
            !Object.prototype.hasOwnProperty.call(item, '应知应会')
          ) {
            excelData[index][name2] = excelData[index - 1][name2]
          }

          if (!Object.prototype.hasOwnProperty.call(item, '应知应会')) {
            item.应知应会 = excelData[index - 1].应知应会
          }
          importDataList.push({
            Question: item['判断题（答案是）'],
            DisturbQuestion: item['判断题（答案否）'],
            Interpretation: item.应知应会,
            RiskPoints: '',
            RiskMeasures: '',
            Theme: item.专题,
            ChoiceQue: item.选择题,
            ChoiceQueOption: item.选择题答案,
            ChoiceQueAnswer: item.选择题正确答案,
          })
          $this.tableData.push({
            interpretation: item.应知应会,
            theme: item.专题,
          })
        })
        if (importDataList.length != 0) {
          $this.importData(importDataList)
        } else {
          $this.$message({
            type: 'warning',
            message: '导入文件不符合',
          })
        }
      }
      if (this.rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },
    importData(list) {
      post('/api/ThemeQuestionPool/BatchAdd', {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd?'添加成功':'导入成功',
            })
            this.showDialog = false
            this.getTableData()
            this.getThemeList()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('导入失败')
        })
    },
    // 增删改
    addQuestion() {
      this.showDialog = true
      this.isAdd = true
      var form = this.form
      form.Interpretation = ''
      form.Question = ''
      form.DisturbQuestion = ''
      form.ChoiceQue = ''
      form.ChoiceQueOption = ''
      form.ChoiceQueAnswer = ''
      form.Theme = ''
    },
    handleReqData() {
      if (this.isAdd) {
        var list = []
        this.form.RiskPoints = ''
        this.form.RiskMeasures = ''
        list.push(this.form)
        this.tableData.push({
          interpretation: this.form.Interpretation,
          theme: this.form.Theme,
        })
        this.importData(list)
      } else {
        var form = this.form
        var data = {
          id: this.dataItem.id,
          question: form.Question,
          disturbQuestion: form.DisturbQuestion,
          interpretation: form.Interpretation,
          choiceQue: form.ChoiceQue,
          choiceQueOption: form.ChoiceQueOption,
          choiceQueAnswer: form.ChoiceQueAnswer,
          riskPoints: '',
          riskMeasures: '',
          theme: form.Theme,
          version: '',
        }
        put('/api/ThemeQuestionPool', data)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: 'success',
                message: '修改成功',
              })
              this.showDialog = false
              this.getTableData()
            } else {
              this.$message.error(resp.message)
            }
          })
          .catch(() => {
            this.$message.error('修改失败')
          })
      }
    },
    handleClose() {
      this.showDialog = false
    },
    modifyItem(row) {
      this.isAdd = false
      this.dataItem = row
      var form = this.form
      form.Interpretation = row.interpretation
      form.Question = row.question
      form.DisturbQuestion = row.disturbQuestion
      form.ChoiceQue = row.choiceQue
      form.ChoiceQueOption = row.choiceQueOption
      form.ChoiceQueAnswer = row.choiceQueAnswer
      form.Theme = row.theme
      this.showDialog = true
    },
    deleteItem(row) {
      this.$confirm('您确认要删除id为“' + row.id + '”的专题？')
        .then(() => {
          deleteMethod('/api/ThemeQuestionPool?Id=' + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.tableRowIndex = null
                // this.tableData.splice(row.index, 1)
                this.getTableData()
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    selectAll(e) {
      this.checkedDelList = e
    },
    deleteList() {
      if (this.checkedDelList.length == 0) {
        this.$message({
          type: 'warning',
          message: '未勾选列表',
        })
        return
      }
      var idList = []
      this.checkedDelList.map((i) => {
        idList.push({ QuestionId: i.id })
      })
      this.$confirm('您确定要批量删除当前被勾选的专题？')
        .then(() => {
          post('/api/ThemeQuestionPool/BatchDelete', {
            data: JSON.stringify(idList),
          })
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.checkedDelList = []
                this.getTableData()
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang='less' scoped>
._SelectHeader {
  justify-content: space-between;
  padding-right: 20px;
  background-color: white;
  & > div {
    display: flex;
    align-items: center;
  }
  /deep/.el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }
  /deep/.el-checkbox__inner::after {
    height: 11px !important;
    left: 7px !important;
  }
}
._Table {
  background-color: white;
}
.add-dialog {
  .add-select {
    display: flex;
    align-items: center;
    .add-input {
      display: flex;
      align-items: center;
      span {
        width: 140px;
      }
    }
  }
  .el-textarea {
    margin-bottom: 10px;
  }
}
._Select {
  width: 450px;
  .el-select {
    width: 350px;
  }
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
</style>